import React from 'react'
import { useState } from 'react';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Refrash from "./refreshtest";

// Usage
function App() {
  // Similar to useState but first arg is key to the value in local storage.
  const [name, setName] = useLocalStorage<string>('name', '');

  return (
    <div>
    <div>
      <Grid container justify="center">
        <Grid item xs={12} sm={3}>
          <Paper>
          <p>{name}</p>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Paper>
            <TextField
              placeholder="Enter your name"
              value={name}
              onChange={e => setName(e.target.value)}
            >
              テキストフィールド
            </TextField>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Paper>
            <Button variant="contained" color="primary"  onClick={() => { window.location.reload() }}>
              ボタン
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Paper>
            <Refrash/>
          </Paper>
        </Grid>
      </Grid>

      <Grid container justify="center">
        <Grid item xs={12} sm={2}>
          <Paper>
          <p>{name}</p>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Paper>
            <TextField
              placeholder="Enter your name"
              value={name}
              onChange={e => setName(e.target.value)}
            >
              テキストフィールド
            </TextField>
            <Button variant="contained" color="primary"  onClick={() => { window.location.reload() }}>
              ボタン
            </Button>
            <Refrash/>
          </Paper>
        </Grid>
      </Grid>

      {/* <input
        type="text"
        placeholder="Enter your name"
        value={name}
        onChange={e => setName(e.target.value)}
      /> */}
    </div>
    
    </div>
  );
}

// Hook
function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export default App